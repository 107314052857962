import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Story = () => (
  <Layout>
    <SEO
      title="Shophilist App | Thank you!"
      keywords={[
        `shophilist`,
        `mobile app`,
        `app`,
        `shopping list`,
        `groceries`,
        `story`
      ]}
    />
    <Content>
      <Wrapper>
        <Link to="/">← Front page</Link>
        <Header>
          <H2>Thank you for joining our waiting list!</H2>
        </Header>
        <Text>
          You have joined our waiting list and we will inform you when we
          launch!
        </Text>
        <Text>
          We will not send you anything other than the launch message. Your
          email will not be shared with any other parties than Netlify (which
          stores your email address) and us.
        </Text>
        <Text>
          If you want to remove yourself from the waiting list send an email to
          support@shophilist.com.
        </Text>
        <Text>
          - <a href="https://twitter.com/jruokangas">@jruokangas</a>
        </Text>
        <Text>
          <Link to="/">← Front page</Link>
        </Text>
      </Wrapper>
    </Content>
  </Layout>
);

const Content = styled.div`
  background-color: #fff6b9;
  width: 100vw;
  margin: 0 auto;
  flex: 1;
`;

const Wrapper = styled.div`
  background-color: #fff6b9;
  padding: 0 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: 34rem;
  margin: 0 auto;
  color: #607d8b;
`;

const Header = styled.header``;
const Text = styled.p``;
const H2 = styled.h2``;

export default Story;
